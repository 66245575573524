import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  IoIosCall,
  IoIosCheckmark,
  IoIosLink,
  IoIosStar,
  IoIosStarHalf,
} from 'react-icons/io';

const HeroCTA = ({ heading, serviceLocation, description }) => {
  const {
    site: {
      siteMetadata: { phone, phoneFormatted, googleMapsAddress },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
          googleMapsAddress
        }
      }
    }
  `);

  const isBrowser = typeof window !== 'undefined';

  return (
    <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
      <div className="flex">
        <div className="p-3 -m-3 overflow-hidden rounded-lg cta">
          <h1 className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
            {heading}
          </h1>

          <div className="mt-3">
            {[
              {
                id: 1,
                title: '25+ years experience',
              },
              {
                id: 2,
                title: 'Caring & reliable service',
              },
              {
                id: 3,
                title: 'Packing available',
              },
              {
                id: 4,
                title: 'Excellent value',
              },
            ].map((item) => (
              <div key={item.id} className="flex items-center font-semibold">
                <IoIosCheckmark className="flex-shrink-0 -ml-2 text-4xl text-brand-orange" />
                <span className="flex-shrink-0 ml-1 uppercase">
                  {item.title}
                </span>
              </div>
            ))}
            <a
              href={googleMapsAddress}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center font-semibold hover:text-brand-orange"
            >
              <IoIosCheckmark className="flex-shrink-0 -ml-2 text-4xl text-brand-orange" />
              <span className="flex items-center flex-shrink-0 ml-1 uppercase">
                4.7&nbsp;
                <span aria-hidden="true" className="flex text-brand-orange">
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStarHalf />{' '}
                </span>
                <span className="ml-1"> on Google</span>
                <span className="ml-1">
                  <IoIosLink />
                </span>
              </span>
            </a>
          </div>

          <div className="flex flex-wrap max-w-lg mt-4 -mx-2 space-y-4">
            <div className="flex flex-wrap items-center justify-start w-full">
              <p className="w-full px-2 ">
                <Link
                  to="https://www.frontlineremovals.com.au/#bottom-form"
                  // @ts-ignore
                  offset="12"
                  className="flex items-center justify-center shadow whitespace-nowrap w-72 hover:shadow-md button"
                >
                  Get instant quote
                </Link>
              </p>
              {/* <p className="w-full px-2 mt-4 ">
                <Link
                  to="/inventory-form/#inventoryform"
                  // @ts-ignore
                  offset="12"
                  className="flex items-center justify-center shadow whitespace-nowrap w-72 hover:shadow-md button bg-brand-blue"
                >
                  Fill out inventory form
                </Link>
              </p> */}
              <p className="w-full px-2 mt-4 ">
                <a
                  onClick={() => {
                    // @ts-ignore
                    if (isBrowser && window.fbq) {
                      // @ts-ignore
                      window.fbq('track', 'Contact');
                    }
                  }}
                  href={`tel:${phone}`}
                  className="flex items-center justify-center text-black bg-white border-black shadow whitespace-nowrap w-72 hover:shadow-md button"
                >
                  <IoIosCall />
                  <span className="ml-2">Call: {phoneFormatted}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

HeroCTA.propTypes = {
  heading: PropTypes.node.isRequired,
  serviceLocation: PropTypes.string,
  description: PropTypes.string,
};

export { HeroCTA };
