import { Link } from 'gatsby';
import * as React from 'react';

import Box from '../icons/box';
import Hanger from '../icons/hanger';
import Truck from '../icons/truck';

const ThreeIcons = () => {
  const services = [
    {
      icon: Truck,
      slug: '/',
      firstLine: 'Move ',
      secondLine: 'with us',
    },
    {
      icon: Box,
      slug: '/',
      firstLine: 'store ',
      secondLine: 'with us',
    },
    {
      icon: Hanger,
      slug: '/',
      firstLine: 'Buy ',
      secondLine: 'a container',
    },
  ];
  return (
    <article
      className={`max-w-6xl px-6 pt-4 pb-12 mx-auto ${'text-black bg-brand-gray'}`}
    >
      <div className="flex flex-wrap w-full mt-12">
        {services.map((service, index) => (
          <div className="w-full py-4 md:w-1/3 md:py-0">
            <Link
              to={`https://www.frontlineremovals.com.au/${service.slug}`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`flex flex-col items-center justify-center mx-auto text-center ${'bg-brand-gray'} rounded-md`}
            >
              <service.icon className={`w-36 ${'text-brand-blue'}`} />
              <h3 className="pb-4 mt-4 text-4xl font-semibold leading-none text-white uppercase font-display">
                <span className="text-brand-orange">{service.firstLine}</span>
                <span className={`${'text-brand-blue'}`}>
                  {service.secondLine}
                </span>
              </h3>
            </Link>
          </div>
        ))}
      </div>
    </article>
  );
};

export { ThreeIcons };
