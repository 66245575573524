import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { IoIosStar } from 'react-icons/io';

import { Layout, SEO } from '../components';
import SEOPageContactForm from '../components/forms/seo-contact-form';
import {
  Ballina,
  CoffsHarbour,
  FirstSection,
  FooterHero,
  FooterHeroCTA,
  FooterImage,
  Grafton,
  Hero,
  HeroCTA,
  HeroImage,
  Kempsey,
  SecondSection,
  SouthWestRocks,
  Sydney,
  Brisbane,
  Taree,
  NorthernBeaches,
  ByronBay,
  ThirdSection,
  ThreeIcons,
} from '../components/local-removalist';

const LocalRemovalistTemplate = ({
  data: {
    sanityPage,
    allSanityPost: { nodes },
  },
}) => (
  <Layout isSEOPage pageTitle={sanityPage.title}>
    <SEO title={sanityPage.title} description={sanityPage.description} />
    {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
    {sanityPage.content.map((i) => {
      if (i._type && i._type === 'sectionType1') {
        return (
          <FirstSection
            key={i._key}
            heading={(
              <>
                {i.copyHeading.map((line, index) => (
                  <>
                    {line}
                    {index !== i.copyHeading.length - 1 && <br />}
                  </>
                ))}
              </>
            )}
            image={i.copyImage && i.copyImage.asset.fluid}
            alt={i.copyImageAlt}
            copy={<Markdown>{i.body}</Markdown>}
          />
        );
      }
      if (i._type && i._type === 'sectionType2') {
        return (
          <SecondSection
            key={i._key}
            whiteBg={i.whiteBg}
            headingLine1={i.section2TitleLine1}
            headingLine2={i.section2TitleLine2}
            copy={<Markdown>{i.section2copy}</Markdown>}
          />
        );
      }
      if (i._type && i._type === 'threeIcons') {
        return <ThreeIcons />;
      }
      if (i._type && i._type === 'southWestRocks') {
        return <SouthWestRocks />;
      }
      if (i._type && i._type === 'grafton') {
        return <Grafton />;
      }
      if (i._type && i._type === 'coffsHarbour') {
        return <CoffsHarbour />;
      }
      if (i._type && i._type === 'sydney') {
        return <Sydney />;
      }
      if (i._type && i._type === 'brisbane') {
        return <Brisbane />;
      }
      if (i._type && i._type === 'taree') {
        return <Taree />;
      }
      if (i._type && i._type === 'byronBay') {
        return <ByronBay />;
      }
      if (i._type && i._type === 'northernBeaches') {
        return <NorthernBeaches />;
      }
      if (i._type && i._type === 'taree') {
        return <Taree />;
      }
      if (i._type && i._type === 'ballina') {
        return <Ballina />;
      }
      if (i._type && i._type === 'kempsey') {
        return <Kempsey />;
      }
      if (i._type && i._type === 'blogSection') {
        return (
          <>
            <div className="w-full max-w-5xl mx-auto my-auto mt-16 text-center">
              <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
                <span className="inline-block text-brand-orange">
                  Learn more{' '}
                </span>
                <span className=""> About us</span>
              </p>
            </div>

            <div className="grid w-full grid-cols-1 gap-12 px-4 mt-8 mb-16 max-w-none sm:mt-8 lg:grid lg:grid-cols-3 lg:gap-12">
              {nodes.map(
                (post) =>
                  i < 3 && (
                    <div
                      key={post.id}
                      className="flex duration-300 ease-in-out transform bg-white rounded-lg shadow hover:shadow-xl hover:scale-105"
                    >
                      <div className="flex flex-col flex-1 overflow-hidden rounded-lg shadow-sm">
                        <Link
                          to={`https://www.frontlineremovals.com.au/blog/${post.slug.current}/`}
                          className="focus:outline-none group"
                        >
                          <GatsbyImage
                            fluid={post.mainImage.asset.fluid}
                            className="object-cover w-full h-64"
                            alt={`Blog post - ${post.title}`}
                          />
                          <div className="px-6 mt-4">
                            <h3
                              title={post.title}
                              className="text-3xl leading-tight line-clamp-1 text-brand-blue font-display group-focus:underline"
                            >
                              {post.title}
                            </h3>
                            <div className="mt-4">
                              <p
                                title={post.description}
                                className="line-clamp-3"
                              >
                                {post.description}
                              </p>
                            </div>
                          </div>
                        </Link>
                        <p className="px-6 pt-2 my-4 text-gray-600">
                          <time>{post._createdAt}</time>
                        </p>
                        <div className="flex flex-wrap items-start flex-1 px-6 mt-auto">
                          <div className="py-4">
                            {post.categories.map((category) => (
                              <button
                                type="button"
                                key={category.title}
                                className={`inline-block px-3 py-1 mt-4 mr-2 text-sm font-semibold text-white transition duration-150 ease-in-out rounded hover:bg-brand-blue focus:outline-none focus:ring focus:bg-brand-blue ${'bg-brand-blue'}`}
                              >
                                #{category.title}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </>
        );
      }
      if (i._type && i._type === 'testimonials') {
        return (
          <article className="px-4 py-12 mx-auto mb-24 text-white sm:px-12 bg-brand-blue max-w-1440">
            <div className="">
              <h2 className="mb-12 text-5xl font-semibold leading-none text-center uppercase font-display">
                What Our <span className="text-brand-orange">Clients Say</span>
              </h2>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full px-12 mt-6 md:mt-0 md:w-1/3">
                <span
                  aria-hidden="true"
                  className="flex justify-center mb-6 text-4xl text-white"
                >
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                </span>
                <div className="text-center rich-text">{i.quote1}</div>
                <div className="mt-3 italic text-center rich-text">
                  {i.name1}
                </div>
              </div>
              <div className="w-full px-12 mt-6 md:mt-0 md:w-1/3">
                <span
                  aria-hidden="true"
                  className="flex justify-center mb-6 text-4xl text-white"
                >
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                </span>
                <div className="text-center rich-text">{i.quote2}</div>
                <div className="mt-3 italic text-center rich-text">
                  {i.name2}
                </div>
              </div>
              <div className="w-full px-12 mt-6 md:mt-0 md:w-1/3">
                <span
                  aria-hidden="true"
                  className="flex justify-center mb-6 text-4xl text-white"
                >
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                </span>
                <div className="text-center rich-text">{i.quote3}</div>
                <div className="mt-3 italic text-center rich-text">
                  {i.name3}
                </div>
              </div>
            </div>
          </article>
        );
      }
      if (i._type && i._type === 'hero') {
        return (
          <Fragment key={i._key}>
            <Hero
              image={(
                <HeroImage
                  fluid={i.heroImage.asset.fluid}
                  alt={i.heroImageAlt}
                />
              )}
              // @ts-ignore
              photoCredit={i.photoCredit}
              cta={(
                <HeroCTA
                  heading={(
                    <>
                      {i.titleLine1} <br />
                      <span className="">{i.titleLine2}</span>
                    </>
                  )}
                  description={i.description}
                  serviceLocation={i.titleLine2}
                />
              )}
            />
          </Fragment>
        );
      }
      if (i._type && i._type === 'footerHero') {
        return (
          <FooterHero
            key={i._key}
            image={(
              <FooterImage
                fluid={i.footerImage.asset.fluid}
                alt={i.footerImageAlt}
              />
            )}
            cta={(
              <FooterHeroCTA
                serviceLocation={i.serviceLocation}
                heading={(
                  <>
                    <span className="text-brand-orange">
                      {i.footerHeading[0]}
                    </span>
                    {i.footerHeading.map((line, index) => (
                      <>
                        {index > 0 && line}
                        {index !== i.footerHeading.length - 1 && <br />}
                      </>
                    ))}
                  </>
                )}
              />
            )}
          />
        );
      }
      if (i._type && i._type === 'sectionType3') {
        return (
          <ThirdSection
            key={i._key}
            fullWidth={i.fullWidth}
            heading={(
              <>
                <span className="text-brand-orange">{i.copyHeading[0]}</span>
                {i.copyHeading.map((line, index) => (
                  <>
                    {index > 0 && line}
                    {index !== i.copyHeading.length - 1 && <br />}
                  </>
                ))}
              </>
            )}
            image={i.copyImage ? i.copyImage.asset.fluid : ''}
            copy={<Markdown>{i.body}</Markdown>}
            alt={i.copyImageAlt}
          />
        );
      }
      return null;
    })}

    {/* <div id="bottom-form" className="relative py-12">
      <SEOPageContactForm formName="seo-page-moving-quote-form" />
    </div> */}
  </Layout>
);

LocalRemovalistTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query PageTemplateQuery($slug__current: String!) {
    sanityPage(slug: { current: { eq: $slug__current } }) {
      content {
        ... on SanitySectionType1 {
          _key
          _type
          copyHeading
          copyImageAlt
          copyImage {
            asset {
              fluid(maxWidth: 1024) {
                ...GatsbySanityImageFluid
              }
            }
          }
          body
        }
        ... on SanitySectionType2 {
          _key
          _type
          whiteBg
          section2copy
          section2TitleLine2
          section2TitleLine1
        }
        ... on SanitySectionType3 {
          _key
          _type
          copyHeading
          fullWidth
          copyImageAlt
          copyImage {
            asset {
              fluid(maxWidth: 1024) {
                ...GatsbySanityImageFluid
              }
            }
          }
          body
        }
        ... on SanityTestimonials {
          _key
          _type
          name1
          name2
          name3
          quote1
          quote2
          quote3
        }
        ... on SanityThreeIcons {
          _key
          _type
          displayIt
        }
        ... on SanityBlogSection {
          _key
          _type
          displayIt
        }
        ... on SanitySouthWestRocks {
          _key
          _type
          displayIt
        }
        ... on SanityNorthernBeaches {
          _key
          _type
          displayIt
        }
        ... on SanityGrafton {
          _key
          _type
          displayIt
        }
        ... on SanityBallina {
          _key
          _type
          displayIt
        }
        ... on SanityCoffsHarbour {
          _key
          _type
          displayIt
        }
        ... on SanitySydney {
          _key
          _type
          displayIt
        }
        ... on SanityBrisbane {
          _key
          _type
          displayIt
        }
        ... on SanityTaree {
          _key
          _type
          displayIt
        }
        ... on SanityByronBay {
          _key
          _type
          displayIt
        }
        ... on SanityKempsey {
          _key
          _type
          displayIt
        }
        ... on SanityHero {
          _key
          _type
          titleLine1
          titleLine2
          description
          heroImageAlt
          heroImage {
            asset {
              fluid(maxWidth: 1024) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityFooterHero {
          _key
          _type
          footerImageAlt
          footerImage {
            asset {
              fluid(maxWidth: 1024) {
                ...GatsbySanityImageFluid
              }
            }
          }
          footerHeading
          serviceLocation
        }
      }
      description
      googleMapsEmbed
      title
    }
    allSanityPost(sort: { fields: _createdAt }) {
      nodes {
        _createdAt(formatString: "MMMM DD, YYYY")
        body
        categories {
          title
        }
        description
        id
        mainImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        title
      }
    }
  }
`;

export default LocalRemovalistTemplate;
