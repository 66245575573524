import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

const FooterHeroCTA = ({ heading, serviceLocation }) => {
  const {
    site: {
      siteMetadata: { phone, phoneFormatted },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
          googleMapsAddress
        }
      }
    }
  `);

  return (
    <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
      <div className="flex">
        <div className="p-3 -m-3 overflow-hidden rounded-lg cta">
          {serviceLocation === 'South West Rocks' ? (
            <h2 className="mt-16 text-5xl font-semibold leading-none uppercase sm:text-6xl heading font-display text-brand-blue">
              {heading}
            </h2>
          ) : (
            <h2 className="mt-16 text-6xl font-semibold leading-none uppercase heading font-display text-brand-blue">
              {heading}
            </h2>
          )}
          <p className="mt-4 font-semibold tracking-wider rich-text">
            Looking for{' '}
            {serviceLocation === 'Sydney '
              ? 'removalist services '
              : 'a proven removalist in '}
            <br />
            {serviceLocation === 'Sydney ' ? 'Sydney' : serviceLocation}? For
            more information <br />
            on quality removalist solutions in
            <br />
            {serviceLocation === 'Sydney ' ? 'Sydney' : serviceLocation}, call
            Frontline Removals on
          </p>
          <a className="font-bold" href={`tel:${phone}`}>
            {phoneFormatted}
          </a>{' '}
          <p className="inline-block font-semibold tracking-wider rich-text">
            today.
          </p>
          <div className="flex flex-wrap mt-6 -mx-2">
            <p className="flex-shrink-0 w-full px-2 sm:w-auto">
              <Link
                to="https://www.frontlineremovals.com.au/furniture-removal/#moving-page-top-form"
                offset="12"
                className="flex items-center justify-center w-full mt-4 bg-white shadow sm:w-56 hover:shadow-md button border-brand-orange text-brand-orange"
              >
                Contact us today
              </Link>
            </p>
          </div>
        </div>
      </div>
    </article>
  );
};

FooterHeroCTA.propTypes = {
  heading: PropTypes.node.isRequired,
  serviceLocation: PropTypes.string,
};

export { FooterHeroCTA };
