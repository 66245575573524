import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import * as React from 'react';

const FirstSection = ({ heading, image, copy, alt }) => (
  <>
    <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-32">
      <div className="flex flex-col w-full md:w-1/3">
        <h2 className="text-5xl font-semibold leading-none uppercase heading font-display">
          {heading}
        </h2>
        {/* {image && (
            <div className="hidden my-auto mr-12 overflow-hidden rounded-md md:block">
              <GatsbyImage fluid={image} alt={alt} />
            </div>
          )} */}
      </div>
      <div className="w-full mt-6 md:mt-0 md:w-2/3">
        <div className="rich-text">{copy}</div>
      </div>
    </article>
    {image && (
      <div className="max-w-6xl mx-auto my-auto mt-6 -mb-12 overflow-hidden">
        <GatsbyImage fluid={image} alt={alt} />
      </div>
    )}
  </>
);

FirstSection.propTypes = {
  heading: PropTypes.node.isRequired,
  image: PropTypes.object,
  copy: PropTypes.node.isRequired,
  alt: PropTypes.string,
};

export { FirstSection };
